// Floodlight tags

export default {

	data(){
		return {
			globalScriptLandingPageIsDeployed: false,
			eventScriptLandingPageIsDeployed: false,
			globalScriptThanksPageIsDeployed: false,
			eventScriptThanksPageIsDeployed: false,
		}
	},

	methods: {

	/* These scripts are triggered by goto(dest) function (see mixins/Functions.js), 
	when dest === "inscription" or "confirmation" */

	createGlobalScriptForLandingPage(){
		if(!this.globalScriptLandingPageIsDeployed){
			console.log("Deploying Global Landing Floodlight script");	
			let script = document.createElement('script');
			script.setAttribute('id', "globland");
			script.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=DC-10803030");
			script.setAttribute('async', '');
			script.innerHTML = `
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'DC-10803030');`;
			document.head.append(script);
			console.log("The script should be deployed");
			this.globalScriptLandingPageIsDeployed = true;
		}
	},

	createEventScriptForLandingPage(){
		if(!this.eventScriptLandingPageIsDeployed){
			console.log("Deploying Event Landing Floodlight script");
			let script = document.createElement('script');
			script.setAttribute('id', "eventland");
			script.innerHTML = `
			gtag('event', 'conversion', {
				'allow_custom_scripts': true,
				'u1': '[Language]',
				'u2': '[Site name]',
				'u3': '[URL]',
				'u4': '[Page Name]',
				'u5': '[Funnel Step Name]',
				'send_to': 'DC-10803030/highcolp/herta0+standard'
			})`;
			let noscript = document.createElement('noscript');
			noscript.innerHTML = '	<img src="https://ad.doubleclick.net/ddm/activity/src=10803030;type=highcolp;cat=herta0;u1=[Language];u2=[Site name];u3=[URL];u4=[Page Name];u5=[Funnel Step Name];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';
			document.body.prepend(script);
			document.body.prepend(noscript);
			console.log("The script should be deployed");
			this.eventScriptLandingPageIsDeployed = true;
		}
	},

	createGlobalScriptForThanksPage(){
		// remove the global landing script
		let elToRemove = document.getElementById("globland");
		elToRemove.remove();

		if(!this.globalScriptThanksPageIsDeployed){
			console.log("Deploying Global Thanks Floodlight script");
			let script = document.createElement('script');
			script.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=DC-10803030");
			script.setAttribute('async', '');
			script.innerHTML = `
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'DC-10803030');`;
			document.head.append(script);
			console.log("The script should be deployed");
			this.globalScriptThanksPageIsDeployed = true;
		}
	},

	createEventScriptForThanksPage(){
		// remove the event landing script
		let elToRemove = document.getElementById("eventland");
		elToRemove.remove();

		if(!this.eventScriptThanksPageIsDeployed){
			console.log("Deploying Event Thanks Floodlight script");
			let script = document.createElement('script');
			script.innerHTML = `
			gtag('event', 'conversion', {
				'allow_custom_scripts': true,
				'u1': '[Language]',
				'u2': '[Site name]',
				'u3': '[URL]',
				'u4': '[Page Name]',
				'u5': '[Funnel Step Name]',
				'send_to': 'DC-10803030/highcolp/herta00+unique'
			});
			`;
			let noscript = document.createElement('noscript');
			noscript.innerHTML = '	<img src="https://ad.doubleclick.net/ddm/activity/src=10803030;type=highcolp;cat=herta00;u1=[Language];u2=[Site name];u3=[URL];u4=[Page Name];u5=[Funnel Step Name];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?" width="1" height="1" alt=""/>';
			document.body.prepend(script);
			document.body.prepend(noscript);
			console.log("The script should be deployed");
			this.eventScriptThanksPageIsDeployed = true;
		}
	},


},
}