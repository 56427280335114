export default {
    data () {
        return {
            userEnvDetails: [],
        }
    },
    methods: {

        getUserEnvironment(){
            /* GET some data about the device of the user to be sent for possible debug help */
            this.userEnvDetails.push(window.screen); // for display problems
            this.userEnvDetails.push(window.navigator); // for functionnal problems
            this.userEnvDetails.push(window.location); // for utm and keys
            this.displayInfos();
        },

        displayInfos(){
          this.log("ENVIRONMENT INFORMATIONS", "sep");
          this.log(this.userEnvDetails[0]);
          this.log(this.userEnvDetails[1]);
          this.log(this.userEnvDetails[2]);
      }

  },
}
