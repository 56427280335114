<template>
    <div id ="popupWrapper">

        <div class="popup-button" @click="togglePopup()">
            X
        </div>

        <div id="popUpContent" style="display:none;">

            <div class="popUpText1">
                <div class="textWrapper">
                    <h4>{{$t('popUp.title')}}</h4>
                    <p class="col2">{{$t('popUp.text')}}</p>
                    <ul>
                        <li>{{$t('popUp.ul.li1')}}<br/><br/>{{$t('popUp.ul.li2')}}</li>
                        <li>{{$t('popUp.ul.li3')}}</li>
                        <li v-if="$i18n.locale ==='nl_BE' || $i18n.locale ==='nl_NL'">{{$t('popUp.ul.li4')}}<br/><br/>{{$t('popUp.ul.li5')}}<br/><br/><a :href="$t('popUp.ul.li6Link')" target="_blank">{{$t('popUp.ul.li6LinkLabel')}}</a>{{$t('popUp.ul.li6')}} <a :href="$t('popUp.ul.li6Link2')" target="_blank">{{$t('popUp.ul.li6LinkLabel2')}}</a></li>
                        <li v-else>{{$t('popUp.ul.li4')}}<br/><br/>{{$t('popUp.ul.li5')}}<br/><br/>{{$t('popUp.ul.li6')}} <a :href="$t('popUp.ul.li6Link')" target="_blank">{{$t('popUp.ul.li6LinkLabel')}}</a> {{$t('popUp.ul.li6Part2')}} <a :href="$t('popUp.ul.li6Link2')" target="_blank">{{$t('popUp.ul.li6LinkLabel2')}}</a></li>
                    </ul>
                </div>

            </div>
        </div>

        <div id="popUpContent2" style="display:none;">

            <div class="popUpText1">
                <div class="textWrapper">
                    <h4>{{$t('popUp2.title')}}</h4>
                    <ul>
                        <li>{{$t('popUp2.ul.li1')}}</li>
                        <li>{{$t('popUp2.ul.li2')}} <a :href="$t('popUp2.ul.li2Link')" target="_blank">{{$t('popUp2.ul.li2LinkLabel')}}</a> {{$t('popUp2.ul.li2Part2')}}</li>
                        <li>{{$t('popUp2.ul.li3')}} <a :href="$t('popUp2.ul.li3Link')" target="_blank">{{$t('popUp2.ul.li3LinkLabel')}}</a> {{$t('popUp2.ul.li3Part2')}} <a :href="$t('popUp2.ul.li3Link2')" target="_blank">{{$t('popUp2.ul.li3LinkLabel2')}}</a> {{$t('popUp2.ul.li3Part3')}}</li>
                        <li>{{$t('popUp2.ul.li4')}} <a :href="$t('popUp2.ul.li4Link')" target="_blank">{{$t('popUp2.ul.li4LinkLabel')}}</a> {{$t('popUp2.ul.li4Part2')}}</li>
                        <li>{{$t('popUp2.ul.li5')}} <a :href="$t('popUp2.ul.li5Link')" target="_blank">{{$t('popUp2.ul.li5LinkLabel')}}</a> {{$t('popUp2.ul.li5Part2')}}</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    import Functions from "../mixins/Functions";

    export default {
        name: "popup",
        mixins: [
            Functions,
        ],

        data(){
            return {
                amIVisible : true
            };
        },
        methods: {

            togglePopup(){
                let popup = document.getElementById('popup');
                if (popup.style.display === 'none') {
                    popup.style.display = 'block'; // Show the popup
                } else {
                    popup.style.display = 'none'; // Hide the popup
                }
            }
        }
    }
</script>

<style scoped>


    #popupWrapper{
    }
    .popup-button{
        font-size:1.5em;
        background-color: #000;
        color: #fff;
        font-weight: bold;
        position: fixed;
        top: 10px;
        right: 10px;
        margin-top:1em;
        padding:0.5em 1em;
        cursor: pointer;
        text-align: center;
        z-index: 9999;
        transition:100ms;
    &:hover {
        background-color: #bfc3c6;
        transition:100ms;
     }
    }
    #popUpContent{
        position:relative;
    }

    img, p{
        display:inline;
    }

    .popUpLogo img{

        display:none;

    }

    .popUpText1{
        display:flex;
        align-items: center;
        padding: 20px;

    }

    @media screen and (max-width: 600px){
        #popUpContent {
            top: 0%;
            margin-top: 0px;
        }

        .popUpText1{
            flex-direction: column;
            height:100%;
        }
        .imgWrapper img{
            width:100%
        }
    }
    .textWrapper{
        padding: 1em;
    }
    li{
        padding: 1em 0px;
        list-style-type: disc;
        list-style-position: inside;
    }

</style>