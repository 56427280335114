<template>
	<div id="classicFooter">

		<ul>
			<li>
				<a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('footer.genterms')}}</a>
			</li>
			<li v-if="$t('footer.privacy.link').length > 0">
				<!-- Link must be specified in translations files to appears -->
				<a :href="$t('footer.privacy.link')" target="_blank" rel="noopener">{{$t('footer.privacy.label')}}</a>
			</li>
			<li v-if="$t('footer.cookies.link').length > 0">
				<!-- Link must be specified in translations files to appears -->
				<a :href="$t('footer.cookies.link')" target="_blank" rel="noopener">{{$t('footer.cookies.label')}}</a>
			</li>
		</ul>

		<ul style="margin-top:2em;">
			<!-- Link must be dynamic following the language -->
			<li>CONTACT :</li>
			<li>
				<a v-if="$i18n.locale === 'nl_BE' || $i18n.locale === 'nl_NL'" href="mailto:infolijn@promolife.be">infolijn@promolife.be</a>
				<a v-if="$i18n.locale != 'nl_BE' && $i18n.locale != 'nl_NL'" href="mailto:infoligne@promolife.be">infoligne@promolife.be</a>
			</li>
			<li>
				<a v-if="$i18n.locale === 'nl_BE' || $i18n.locale === 'nl_NL'" href="tel:+3223520390">+32 2 352 03 90</a>
				<a v-if="$i18n.locale != 'nl_BE' && $i18n.locale != 'nl_NL'" href="tel:+3223520398">+32 2 352 03 98</a>			
			</li>
		</ul>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	import openPDF from "../mixins/openpdf";

	export default {
		name:"ClassicFooter",
		mixins: [openPDF],
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
