import store from "../store/store";
import axios from "axios";
import config from "@/config";

/**
 * @class BearerTokenManager
 * @classdesc
 * Get a bearer token object from Action api Laravel Passport OAuth server.
 **/
 export default class BearerTokenManager {
    constructor() {
        this.token = null;
    }

    /**
     * @function getNewTokenFromApi
     * @description Get a bearer token from the server.
     * @returns {Promise<Token>}
     **/
     getNewTokenFromApi() {
        return axios.get(`${config.tokenUrl}/token/${config.refhd}`)
            .then((res) => {
                const token = res.data;
                store.commit("SET_BEARER_TOKEN", token);
                return token
            }).catch((err) => {
                console.log(err);
            });
    }
}
