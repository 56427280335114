/* Related to the Toaster, that display info or error messages for the user */

export default {
    data () {
        return {
            isThereMessage: false,
            messageContent: [],
            messageTitle: '',
            messageType: "alert",
        }
    },
    methods: {
        /* Modes are: info|alert */
        /* Note: message must be an array to display correctly */
        displayMessage(title, message, mode='info') {
            this.log("Displaying message by toaster", 'low');
            this.isThereMessage = true;
            this.messageTitle = title;

            if(!Array.isArray(message)){
                console.log("message n'était pas un array");
                message = [message];
            }

            this.messageContent = message;
            this.messageType = mode;
        },

        hideMessage() {
            this.isThereMessage = false;
        },

        /* DISPLAY AN ERROR MESSAGE FOR THE USER */
        displayErrorWith(error, def="An undefined error has occurred"){
            this.log("ERROR",'group');
            this.log(error);
            this.log(error?.error?.data?.message);
            this.log(error.def);
            this.log(error.data);

            this.log(error?.data?.message);
            this.log(error?.response);
            this.log(error?.response?.data);
            this.log(error?.response?.data?.message);
            this.log(error?.response?.data?.notifications);
            this.log('','groupEnd');

            /* in case of error 500 or 404, is there a response from the API ? */
            /* Default values, if "error" doesn't contain all the needed data */        
            let errorTitle = "Error";
            let errorMessage = def;

            /* Check each element individually, if there is some, replace default values */
            if (error.response?.length > 0) {
                errorTitle = `${error.response.statusText} (${error.response.status})`;
            }
            if (error.response?.data?.message?.length > 0){
                errorMessage = error.response.data.message;
            }
            if (error.response?.data?.notifications?.length > 0){
                // errorTitle = error.response.data.message; // "the given data is invalid"
                errorMessage = error.response.data.notifications;
            }
            /* When every text is there, display an error */
            this.displayMessage(errorTitle, [errorMessage], 'alert');
            
        },
    },
}
