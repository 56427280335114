export default {
	data () {
		return {
			theFullFieldsList : null,
			theMetaFieldsList : null,
			requiredFieldsList : null,
			requiredFieldsShortList : null,
			metaOtherFreeFieldsIds : [],
		}
	},
	methods: {

		getMetaIds(){
			this.log("getMetaIds", 'wip');
			this.getFromAxios(this.callConfigs.getMetasIds);
		},

		filterMetaFields(fullList){
			/* extract a META field list */
			return fullList.filter(el => el.tablename === "ParticipationMetaData");

		},
		filterRequiredFields(fullList){
			/* extract a MANDATORY field list */
			return fullList.filter(el => el.mandatory);
		},

		compareMetaSequence(a,b){
			if (a.sequence < b.sequence){
				return -1;
			}
			if (a.sequence > b.sequence){
				return 1;
			}
			this.log("Two Meta have the same sequence nummer", 'alert');
			return 0;
		},

		sortArrayOfMetaBySequence(arr){
			return arr.sort(this.compareMetaSequence);
		},

		shortenRequiredFieldList(){
			let shortList = [];
			this.requiredFieldsList.forEach(el => {
				shortList.push(el.fieldname);
			});			
			return shortList;
		},

		/* ! should be moved : it gets the fields, not only the metas */
		whatIfIGetTheFields(data){
			/* Setting a marker */
			this.metaDataAreReceived = true;
			
			// this.log(data, "table");	
			this.theFullFieldsList = data;
			this.theMetaFieldsList = this.sortArrayOfMetaBySequence(this.filterMetaFields(data));
			this.requiredFieldsList = this.filterRequiredFields(data);
			this.requiredFieldsShortList = this.shortenRequiredFieldList();
			/* Displaying these list in the console */
			this.displayRequiredFieldsList();
			this.displayMetaFieldsList();
			/* this.displayFullFieldsList(); */


			/* ! MUST CLEAN BELOW, but it's currently used to create the meta */
			let tableIds = [];
			let metaList = this.theMetaFieldsList;
			for (let item in metaList){
				tableIds.push(metaList[item].idleadtmd);
			}
			this.metaIds = [...tableIds];
			
			this.log("META's IDs :", 'puce');
			this.log(this.metaIds);
			this.log("in whatIfIGetTheFields — to be continued", "wip");

			/* Fill fieldsValues with null data for futur usage */
			this.fillFieldsValueSurvey(metaList.length);

			/* Extract information about answers and freeanswers */
			this.isThereFreeAnswersInThisForm(data);
		},

		fillFieldsValueSurvey(num){
			for (let i=0; i< num; i++){
				this.fieldsValues.survey['answersSQ' + (i+1)] = null;		
				this.fieldsValues.survey['answersSQ' + (i+1) + 'alt'] = null;	
			}
		},

		whatIfIDontGetTheFields(err){
			this.log(err, 'alert');
		},

		displayListOfMetaDescription(list){
			this.log("Meta questions","group");
			list.forEach(el => {
				this.log(el.idleadtmd + " : " + el.leadmanagementmetadata.description);
			})
			this.log("","groupEnd");
		},

		displayRequiredFieldsList(){
			this.log("Mandatory Fields", "sep");
			this.log(this.requiredFieldsShortList.join(', '));
			this.log(this.requiredFieldsList, "table");
		},
		displayMetaFieldsList(){
			this.log("Meta Fields", "sep");
			this.log(this.theMetaFieldsList, "table");
			this.displayListOfMetaDescription(this.theMetaFieldsList);
		},
		displayFullFieldsList(){
			this.log("All Fields", "sep");
			this.log(this.theFullFieldsList, "table");
		},

		isThereFreeAnswersInThisForm(data){
			this.log("isThereFreeAnswersInThisForm", "function");
			/* This function show in the console if a freeanswer must be created for a specific meta-field */
			/* ! Currently not a dynamic solution. If the ID change in the LEAD, I'm informed of the new ID via the console, but the form won't work anymore until I fix it */
			let filteredData = data.filter( field => field.fieldname === 'answer' );
			for (let field of filteredData){
				for (let answer of field.leadmanagementmetadata.leadmanagementmetadatavalue){
					if (parseInt(answer.freeanswer) === 1){
						this.metaOtherFreeFieldsIds.push(answer.id);
						let verb = parseInt(answer.freeanswermandatory)===1?"MUST":"COULD";
						this.log(`There ${verb} be a freeanswer for value ${answer.id} given in the field ${answer.idleadtmd} (question: "${field.leadmanagementmetadata.description}") `, "info");
					}
				}
			}
		},





	}
}
